<template lang="pug">
v-app
  v-app-bar(
    app
    dark
    color="#1c3f4c"
  )
    v-app-bar-nav-icon(@click="drawer = !drawer")
    v-toolbar-title
      v-img(
        :src="require('../assets/logo.png')"
        contain
        width="300"
        @click="$router.push('/main')"
        class="logo"
      )
    v-spacer
    div(class="d-flex align-center")
      v-menu(
        v-model="userBox"
        :close-on-content-click="false"
        offset-y
        offset-x
      )
        template(v-slot:activator="{ on, attrs }")
          v-avatar(color="indigo" size="40" v-bind="attrs" v-on="on")
            v-icon(dark) mdi-account-circle
        v-card(
          class="pa-3"
          min-width="200"
        )
          p 帳號: {{ userInfo }}
          v-btn(
            @click="logout"
            class="my-1"
            color="blue-grey"
            dark
            block
          ) 登出
  v-navigation-drawer(
    v-model="drawer"
    app
    fixed
    width="200"
  )
    v-list
      v-list-group(
        v-for="item in items"
        :key="item.stratumName"
        v-model="item.active"
        prepend-icon="mdi-hand-coin-outline"
        :append-icon="item.items ? '$expand' : ''"
      )
      
        template(v-slot:activator)
          v-list-item-content(@click="handleClickMenu(item)")
            v-list-item-title(v-text="item.stratumName")
        v-list-item(
          v-for="child in item.items"
          :key="child.stratumName"
          @click="$router.push(child.router).catch(()=>{})"
          class="pl-8"
        )
          v-list-item-icon(class="mr-5")
            v-icon(v-text="child.action")
          v-list-item-content
            v-list-item-title(v-text="child.stratumName")
  v-main
    router-view
</template>

<script>
import { menu } from "../commonData/commonData";
export default {
  name: "Main",
  data: () => ({
    userBox: false,
    drawer: true,
    group: null,
    items: menu,
  }),
  computed: {
    userInfo() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async getPageList() {
      try {
        let pageRes = await this.$api.auth.getPageList();
        if (pageRes.data.code === 200) {
          this.items = pageRes.data.content;
          this.$store.dispatch("auth/setRight", {
            right: pageRes.data.content
          });
        }
      } catch (err) {
        console.log(err.code);
      }
    },
    handleClickMenu(item) {
      this.$router.push(item.router).catch(() => {});
    },
    logout() {
      this.$store.dispatch("auth/setAuth", {
        token: "",
        isLogin: false,
      });
      this.$store.dispatch("auth/setInfo", {
        user: "",
      });
      this.$router.push("/");
    },
    async checkToken() {
      if (
        !this.$store.state.auth.token ||
        this.$store.state.auth.token === ""
      ) {
        this.$store.dispatch("notification/openNotification", {
          msg: "尚未登入，返回登入頁",
          color: "red",
          timeout: 5000,
          showClose: false,
        });
        this.$router.push("/");
      }
    },
  },
  created() {
    this.checkToken();
    this.getPageList();
  },
};
</script>
<style scoped>
.logo {
  cursor: pointer;
}
</style>
