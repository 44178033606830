const menu = [
  {
    title: "奉獻編號管理",
    action: "mdi-format-list-numbered",
    link: "/dedicationNumber",
  },
  {
    title: "奉獻金額管理",
    action: "mdi-currency-twd",
    link: "/dedicationAmount",
  },
  {
    title: "帳號權限管理",
    action: "mdi-shield-account-outline",
    link: "/account",
  },
];

export { menu };
